
.any {
  background-color: #000000 !important;
}

.boolean {
  background-color: #0000ff !important;
}

.number {
  background-color: #ff0000 !important;
}

.string {
  background-color: #00ff00 !important;
}

.defaultNodeStyle {
  min-height: 30px;
  min-width: 30px;
}
